import styled from '@emotion/styled';

const FlexRowTopLeft = styled.div<{
  gap?: string;
}>`
  height: 100%;

  display: flex;
  vertical-align: top;
  justify-content: left;
  flex-direction: row;
  gap: ${(props) => props.gap || '0'};
`;
export default FlexRowTopLeft;
