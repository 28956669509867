import { graphql, useStaticQuery } from "gatsby";

export const useNewsData = () => {
  const { newsModels } = useStaticQuery(graphql`
    query MyQuery {
      newsModels: allGraphCmsNewsModel4ITips(
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            id
            contentText
            date
            url
          }
        }
      }
    }
  `);
  return newsModels.edges;
};
