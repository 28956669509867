import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";
import MainWrapper from "../components/pages/Career/MainWrapper";
import FlexRowTopLeft from "../components/layout/FlexRowTopLeft";
import styled from "@emotion/styled";
import Spacer from "../components/layout/Spacer";
import Footer from "../components/common/Footer";
import FlexRowCenter from "../components/layout/FlexRowCenter";
import { motion } from "framer-motion";
import { useNewsData } from "../components/pages/home/News/useNewsData";

const NewsPage = () => {
  const newsModels = useNewsData();
  return (
    <Wrapper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0, y: 50 }}
    >
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap');
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Noto+Sans:wght@400;600;700&display=swap');
        </style>
      </Helmet>
      <FlexRowCenter>
        <p id="News" className="title">
          News
        </p>
      </FlexRowCenter>
      <MainWrapper>
        <ul className="newslist">
          {newsModels.map((news: any) => (
            <li>
              <FlexRowTopLeft>
                <time dateTime={news.node.date}>
                  {String(news.node.date).replaceAll("-", ".")}
                </time>
                <a href={news.node.url} target="_blank">
                  <p>{news.node.contentText}</p>
                </a>
              </FlexRowTopLeft>
            </li>
          ))}
        </ul>
      </MainWrapper>
      <Spacer height={"60px"}></Spacer>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  padding-top: min(70px, 10.764%);
  padding-bottom: min(70px, 10.347%);
  font-family: "Noto Sans", sans-serif;
  font-family: "Noto Sans JP", sans-serif;
  color: #ffffff;
  background-color: #204e73;


  .newslist {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    font-family: "Yu Gothic Pr6N";
    //font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 160%;
    font-size: min(1.5vw, 18px);
  }

  .newslist li {
    margin-bottom: 20px;
  }
  .newslist li a {
    text-decoration: underline;
  }

  .newslist li time {
    padding-right: 20px;
  }

  .title {
    font-family: "TT Commons";
    font-size: min(99.94px, 6.94vw);
    letter-spacing: 0.03em;
    font-weight: 700;
    line-height: 160%;
    color: #f2f2f2;
  }
`;

export default NewsPage;
